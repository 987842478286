body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.page {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
}

.container {
  overflow: auto;
  position: relative;
  min-height: min-content;
  flex-grow: 1;
  display: flex;
}

.padding {
  padding: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.noPadding {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.link {
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.cardHeader {
  padding: 25px;
  position: absolute;
  margin-top: -50px;
  margin-left: -5px;
}

.card {
  padding: 20px;
  margin-top: 40px;
}

.anime {
  padding: 10px;
  position: relative;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  color: white;
  min-height: 220px;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.icon:nth-child(2n) {
  animation-name: keyframes1;
  animation-iteration-count: infinite;
  transform-origin: 80% 20%;
}

.icon:nth-child(2n-1) {
  animation-name: keyframes2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: 50% 50%;
}

@keyframes keyframes1 {
  0% {
    transform: rotate(-3deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(8deg);
    animation-timing-function: ease-out;
  }
}

@keyframes keyframes2 {
  0% {
    transform: rotate(2deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(-4deg);
    animation-timing-function: ease-out;
  }
}

.twoFactor {
  background: white;
  border: solid 1px rgba(155, 155, 155, 0.3);
  height: 45px;
  width: 45px;
  border-radius: 4px;
  margin: 2px;
  text-align: center;
  font-size: 20px !important;
  padding-left: 14px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #ffffff inset !important;
  background-color: #ffffff !important;
  background-clip: content-box !important;
}

-webkit-animation: slidetounlock 5s infinite;

@-webkit-keyframes slidetounlock {
  0% {
    background-position: -720px 0;
  }
  100% {
    background-position: 720px 0;
  }
}
